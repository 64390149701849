import posthog from 'posthog-js';
// TODO we need to fix for lucid urls
export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    if (
        !window.location.host.includes('127.0.0.1') &&
        !window.location.host.includes('localhost') &&
        !window.location.host.includes('app-performvu.web.app')
    ) {
        posthog.init(config.public.posthogPublicKey, {
            api_host: 'https://app.posthog.com',
            disable_session_recording: true,
            capture_pageview: false,
            loaded: (posthog) => {
                if (import.meta.env.MODE === 'development') {
                    posthog.debug();
                }
                posthog.onFeatureFlags(() => {
                    if (!posthog.isFeatureEnabled('internal-user')) {
                        posthog.startSessionRecording();
                    }
                });
            },
        });
    }

    return {
        provide: {
            posthog,
        },
    };
});
