import type { AnyVariables, Client, DocumentInput } from '@urql/vue';
import type { DocumentNode } from 'graphql';

export const AddQuery = <T = unknown, P extends AnyVariables = AnyVariables>(
    query: DocumentNode,
    payload?: P,
) => {
    const nuxtApp = useNuxtApp();
    return nuxtApp.$urql.query<T>(query, payload);
};

export const AddMutation = <
    Data = unknown,
    Variables extends AnyVariables = AnyVariables,
>(
    mutation: DocumentInput<Data, Variables>,
    payload?: Variables,
) => {
    const nuxtApp = useNuxtApp();
    return nuxtApp.$urql.mutation(mutation, payload);
};

export const AddMutationWithCustomHeaders = (
    mutation,
    payload,
    customHeaders,
) => {
    const nuxtApp = useNuxtApp();
    const urql = nuxtApp.$urql as Client;
    return urql.mutation(
        mutation,
        { ...payload },
        {
            fetchOptions: {
                headers: {
                    //   "Authorization": 'Test',
                    //   "X-CSRFTOKEN": 'test123'
                    ...customHeaders,
                },
            },
        },
    );
};
