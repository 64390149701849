import { defineStore } from 'pinia';
import { XHR_REQUEST_STATES } from '@/interface/http-loader';

export const useHttpLoaderStore = defineStore('http-loader', {
    state: () => ({
        activeLoaders: {},
        onxhrListChange: null,
    }),
    getters: {
        getLoader(state) {
            return state.activeLoaders;
        },
    },
    actions: {
        update(loaderId: string, reqCurrentState, response) {
            const loaderState = this.activeLoaders[loaderId];
            if (loaderState !== reqCurrentState) {
                this.activeLoaders[loaderId] = reqCurrentState;
                // emitting event on every update
                this.onxhrListChange = {
                    loaderId: loaderId,
                    reqCurrentState: reqCurrentState,
                    response: response,
                };

                // deleting the loader from the list
                if (
                    loaderState === XHR_REQUEST_STATES.ERROR ||
                    loaderState === XHR_REQUEST_STATES.SUCCESS
                ) {
                    this.removeLoader(loaderId);
                }
            }
        },
        removeLoader(loaderId: string) {
            delete this.activeLoaders[loaderId];
        },
    },
});
