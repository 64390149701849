import { defineStore } from 'pinia';
import { Checkout, GetAllProducts } from '~~/api/product.service';
import type {
    CheckoutDataDto,
    PaginatedProducts,
    Partner,
    Product,
} from '~/types/product';
import { yearlyPriceWithDiscount, getPriceDetails } from './helpers/product';

export const useProductStore = defineStore('product', {
    state: () => ({
        productsRaw: null as PaginatedProducts | null,
        partner: null as Partner | null,
    }),
    getters: {
        products(state): Product[] | null {
            return (
                state.productsRaw?.edges
                    .map((product) => {
                        const price = product.node.prices.edges.length
                            ? product.node.prices.edges[0].node
                            : null;
                        const isYearly = price?.recurring.interval === 'year';
                        return {
                            id: product.node.id,
                            stripeProductId: product.node.stripeProductId,
                            stripePriceId: price?.stripePriceId ?? null,
                            isYearly,
                            tile: getPriceDetails(isYearly, this.partner),
                        };
                    })
                    .filter((p): p is Product => !!p.stripePriceId)
                    .sort((a, b) => Number(a.isYearly) - Number(b.isYearly)) ??
                null
            );
        },
        annualProductWithDiscount(): Product | null {
            const product = this.products?.find((p) => p.isYearly);
            if (!product) {
                return null;
            }
            return {
                ...product,
                tile: yearlyPriceWithDiscount,
            };
        },
    },
    actions: {
        async fetchProducts(partner: Partner | undefined) {
            try {
                this.partner = partner ?? null;
                const payload = {
                    filter: {
                        text: '',
                        order: 'DESC',
                        first: 10,
                        userId: '',
                    },
                    loaderId: 'GET_PRODUCTS',
                };
                const { data, error } =
                    await GetAllProducts(payload).toPromise();
                if (!data) {
                    throw error ?? new Error('Products could not be loaded');
                }
                // console.log('fetchProducts', data)
                this.productsRaw = data.fetchActiveProducts;
                return { data, error };
            } catch (e) {
                console.error(e);
            }
        },

        async checkout(checkoutData: CheckoutDataDto) {
            const payload = {
                checkout: checkoutData,
                loaderId: 'CHECKOUT',
            };
            const { data, error } = await Checkout(payload).toPromise();

            return { data, error };
        },
    },
});
