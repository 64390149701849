import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
const VueTelInputOptions = {
    mode: 'international', // working
    // defaultCountry: 'TW', // working
    // onlyCountries: ['TW', 'GH', 'GB', 'US', 'CA'], // working
    // placeholder: 'Phone Number', // not working
    // dynamicPlaceholder: true, // not working
    // enabledCountryCode: true, //not working
    autoFormat: false,
    invalidMsg: 'Invalid phone number',
    validCharactersOnly: true,
    dropdownOptions: {
        showDialCodeInSelection: true,
        showSearchBox: true,
    },
    inputOptions: {
        placeholder: 'Phone Number',
        // invalidMsg: "Please enter correct phone number"
    },
};
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueTelInput, VueTelInputOptions);
});
