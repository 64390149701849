import { defineStore, acceptHMRUpdate } from 'pinia';
import { SendDeviceInfo } from '~~/api/device.api';

export const useStore = defineStore('storeId', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            // Core state - all these properties will have their type inferred automatically
            counter: 10,
            name: 'Test Store',
            isAdmin: true,

            // Breakpoints
            breakpoints: {
                smUp: ['smDown', 'mdDown', 'lgDown', 'xl'],
                mdUp: ['mdDown', 'lgDown', 'xl'],
                lgUp: ['lgDown', 'xl'],
                xlUp: ['xl'],
                xsDown: ['xsDown'],
                smDown: ['smDown', 'xsDown'],
                mdDown: ['mdDown', 'smDown', 'xsDown'],
                lgDown: ['lgDown', 'mdDown', 'smDown', 'xsDown'],
            },
            // UI settings
            ui: {
                header: 'mixed' /* available: mixed, basic, droplist, mega, hamburger, navscroll, search */,
                footer: 'sitemap' /* basic, blog, contact, sitemap */,
                corner: 'chat',
            },
            // Theme settings
           // theme: 'myCustomLightTheme',        removed previous theme setting for now

            // State flags
            loader: false,
            isAuthenticated: false,
            signup: false,
            dialog: false,
            trial: false,
            dataLoaded: false,
            logoTextVisible: true,
        };
    },

    actions: {
        hit() {
            this.counter++;
            useFetch('https://jsonplaceholder.typicode.com/users')
                .then((res) => {
                    return res;
                })
                .then((res) => {
                    console.log(res.data.value);
                });
        },
        setDataLoaded(val = false) {
            this.dataLoaded = val;
        },
       /* changeTheme(theme: string) {
            this.theme = theme;
        }, */ //removed from here due to removal in header/settings.vue for dark mode to launch

        showLoader(value: boolean) {
            this.loader = value;
        },

        async sendDeviceInfo(payload) {
            try {
                const { data, error } =
                    await SendDeviceInfo(payload).toPromise();
                return { data, error };
            } catch (e) {
                console.log('Error occurred while sending device info');
            }
        },

        authenticate(value: boolean) {
            this.isAuthenticated = value;
        },
        setSignup(value: boolean) {
            this.signup = value;
        },
        setTrial(value: boolean) {
            this.trial = value;
        },

        showDialog(value: boolean) {
            this.dialog = value;
        },
    },

    getters: {
        getCount: (state) => state.counter,
        getUser: (state) => {
            state.name;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
