// import {VueRecaptchaPlugin} from 'vue-recaptcha'
// export default defineNuxtPlugin(({ vueApp }) => {
//   const {
//     public: { recaptcha },
//   } = useRuntimeConfig()
//   vueApp.use(VueRecaptchaPlugin, recaptcha)
// })
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    nuxtApp.vueApp.use(VueReCaptcha, {
        loaderOptions: {
            autoHideBadge: true,
            useEnterprise: true,
            // useRecaptchaNet: true,
            // renderParameters: {},
            explicitRenderParameters: {
                badge: 'bottomleft',
            },
        },
        siteKey: config.public.siteKey,
    });
});
