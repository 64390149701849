import { defineStore } from 'pinia';

import {
    Registration,
    AuthenticateUser,
    getUser,
    refreshToken,
    ForgotPassword,
    ResetPassword,
    UpdateProfile,
    UpdatePassword,
    GetInstitutionNameByIp,
    SignInAsInstitution,
    FinishRegistration,
    AuthenticateWithToken,
    GetAccountStatus,
    SendLoginToken,
} from '~~/api/auth.service';
import { Cookies } from 'quasar';
import type {
    FinishRegistrationPayload,
    UpdateUserProfileMutation,
    User,
} from '~/types/user';
import { useProductStore } from './product';
import { UpgradeToAnnualPlan } from '~/api/account.api';
import type { LoginPayload, RegisterPayload } from '~/types/auth';

export const useAuthStore = defineStore('auth', () => {
    const isAuthenticated = ref(false);
    const userData = ref<User | null>(null);
    const isActive = ref(false);
    const isSubscription = ref(false);
    const upgradingPlan = ref(false);
    const institutionName = ref(false);
    const signingInAsInstitution = ref(false);
    const fetchingUser = ref(false);

    const userSubscribedProduct = computed(() => {
        const productStore = useProductStore();
        return (
            productStore.products?.find(
                (p) =>
                    p.stripeProductId === userData.value?.subscription?.product,
            ) ?? null
        );
    });

    const logger = useLogs();
    const { showAndLogError, showSuccessMessage } = useNotifications();
    const router = useRouter();
    const nuxtApp = useNuxtApp();
    const { genericErrorMessage } = useAppConfig();
    const authCookie = useAuthCookie();

    async function registerUser(registration: RegisterPayload) {
        try {
            const { data, error } = await Registration({
                registration,
            }).toPromise();

            if (error || !data?.registration.accessToken) {
                throw error ?? new Error(genericErrorMessage);
            }
            Cookies.set('auth_data', data.registration, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
        } catch (error) {
            logger.error(error);
            throw error;
        }
    }

    async function finishRegistration(
        finishRegistration: FinishRegistrationPayload,
    ) {
        try {
            const { data, error } = await FinishRegistration({
                finishRegistration,
            }).toPromise();

            if (error || !data?.finishRegistration) {
                throw error ?? new Error(genericErrorMessage);
            }
            return true;
        } catch (error) {
            showAndLogError(error);
            return false;
        }
    }
    async function loginUser(loginInput: LoginPayload) {
        try {
            const { data, error } = await AuthenticateUser({
                loginInput,
            }).toPromise();

            if (error || !data?.authenticate.accessToken) {
                throw error ?? new Error(genericErrorMessage);
            }
            Cookies.set('auth_data', data.authenticate, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
        } catch (error) {
            logger.error(error);
            throw error;
        }
    }
    async function getAccountStatus(email: string) {
        try {
            const { data, error } = await GetAccountStatus(email).toPromise();

            if (error || !data) {
                throw error ?? new Error(genericErrorMessage);
            }
            return data.accountStatus;
        } catch (error) {
            showAndLogError(error);
            return null;
        }
    }
    async function sendLoginToken(email: string) {
        try {
            const { data, error } = await SendLoginToken(email).toPromise();

            if (error || !data?.sendLoginToken) {
                throw error ?? new Error(genericErrorMessage);
            }
            showSuccessMessage(data.sendLoginToken.message);
            return true;
        } catch (error) {
            showAndLogError(error);
            return false;
        }
    }
    async function loginWithToken(token: string) {
        try {
            const { data, error } =
                await AuthenticateWithToken(token).toPromise();

            if (error || !data?.authenticateWithToken.accessToken) {
                throw error ?? new Error(genericErrorMessage);
            }
            Cookies.set('auth_data', data.authenticateWithToken, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
            router.push('/success');
        } catch (error) {
            showAndLogError(error);
        }
    }
    async function checkIpAuthStatus() {
        const { data, error } = await GetInstitutionNameByIp().toPromise();
        if (error) {
            logger.error(error);
        }
        if (data) {
            institutionName.value = data.institutionName;
        }
    }
    async function signInAsInstitution() {
        try {
            signingInAsInstitution.value = true;
            const { data, error } = await SignInAsInstitution().toPromise();
            signingInAsInstitution.value = false;
            if (error || !data?.authenticateByIp.accessToken) {
                throw error ?? new Error(genericErrorMessage);
            }
            Cookies.set('auth_data', data.authenticateByIp, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
            router.push('/');
        } catch (error) {
            showAndLogError(error);
        }
    }
    function authenticate(value: boolean) {
        isAuthenticated.value = value;
    }
    function parseJWT(token: string) {
        const parsedPayload = decodeJwt(token);
        isActive.value = parsedPayload.isActive;
        isSubscription.value = parsedPayload.isSubscription;
        return parsedPayload;
    }
    function logout() {
        Cookies.remove('auth_data', { path: '/' });
        localStorage.removeItem('device');
        userData.value = null;
        isActive.value = false;
        isSubscription.value = false;

        nuxtApp.$posthog.reset();
        nuxtApp.$rollbar?.configure({
            payload: {
                person: {
                    id: null,
                },
            },
        });

        authenticate(false);
        router.push('/login');
    }
    async function fetchUser() {
        try {
            fetchingUser.value = true;
            const { data, error } = await getUser().toPromise();
            fetchingUser.value = false;
            if (error || !data) {
                throw (
                    error ?? new Error('Error occurred while fetching profile')
                );
            }
            const { me } = data;
            userData.value = me;

            nuxtApp.$posthog.identify(me.id, {
                email: me.email,
                name: me.name,
            });
            nuxtApp.$rollbar?.configure({
                payload: {
                    person: {
                        id: me.id,
                        email: me.email,
                        username: me.name,
                    },
                },
            });
        } catch (error) {
            showAndLogError(error);
        }
    }
    async function upgradeToAnnualPlan(priceId: string, date: Date) {
        upgradingPlan.value = true;
        const { data, error } = await UpgradeToAnnualPlan({
            priceId,
            date,
        }).toPromise();
        if (error || !data?.upgradeToAnnualPlan) {
            throw error ?? new Error(genericErrorMessage);
        }
        userData.value = data.upgradeToAnnualPlan;
        upgradingPlan.value = false;
    }
    async function getNewToken(tokenExpired = false) {
        try {
            const token = authCookie?.refreshToken;
            if (!token) {
                logger.log('Refresh token is not available');
                return;
            }
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const { data, error } = await refreshToken(headers).toPromise();
            if (!data?.refreshAccess || error) {
                throw error ?? new Error(genericErrorMessage);
            }
            Cookies.remove('auth_data');
            Cookies.set('auth_data', data.refreshAccess, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
            parseJWT(data.refreshAccess.accessToken);
            authenticate(!!data.refreshAccess.accessToken);

            if (tokenExpired) {
                // TODO: implement seamless token refresh
                window.location.reload();
            }
        } catch (error) {
            logout();
            showAndLogError(error);
        }
    }

    async function forgetPassword(obj) {
        try {
            const { data, error } = await ForgotPassword(obj).toPromise();
            if (error || !data?.resetPasswordEmail) {
                throw error ?? new Error('Could not send reset password email');
            }
            showSuccessMessage(data.resetPasswordEmail.message);
        } catch (error) {
            showAndLogError(error);
        }
    }
    async function resetPassword(obj) {
        try {
            const { data, error } = await ResetPassword(obj).toPromise();
            if (error || !data?.resetPassword) {
                throw error ?? new Error('Could not reset password');
            }
            showSuccessMessage(data.resetPassword.message);
            router.push('/login');
        } catch (error) {
            showAndLogError(error);
        }
    }
    async function updatePassword(obj) {
        try {
            const { data, error } = await UpdatePassword(obj).toPromise();
            if (error || !data?.updatePassword) {
                throw error ?? new Error('Could not update password');
            }
            Cookies.set('auth_data', data.updatePassword, {
                path: '/',
                sameSite: 'Lax',
                expires: 7,
            });
            showSuccessMessage('Password updated successfully');
        } catch (error) {
            showAndLogError(error);
        }
    }
    async function updateProfile(
        obj: UpdateUserProfileMutation,
    ): Promise<boolean> {
        try {
            const { data, error } = await UpdateProfile(obj).toPromise();
            if (error || !data?.updateProfile) {
                throw (
                    error ?? new Error('Error occurred while updating profile')
                );
            }
            return true;
        } catch (error) {
            showAndLogError(error);
            return false;
        }
    }
    return {
        isAuthenticated,
        userData,
        isActive,
        isSubscription,
        upgradingPlan,
        institutionName,
        signingInAsInstitution,
        fetchingUser,

        userSubscribedProduct,

        registerUser,
        finishRegistration,
        getAccountStatus,
        loginUser,
        sendLoginToken,
        loginWithToken,
        checkIpAuthStatus,
        signInAsInstitution,
        authenticate,
        parseJWT,
        logout,
        fetchUser,
        upgradeToAnnualPlan,
        getNewToken,
        forgetPassword,
        resetPassword,
        updatePassword,
        updateProfile,
    };
});
