<template>
    <q-btn
        class="bold-font text-h6 auth-button"
        color="secondary"
        text-color="black"
        type="submit"
        no-caps
        rounded
    >
        <slot />
    </q-btn>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.auth-button {
    border-radius: 20px;
    margin: 10px 20px 0;
}
</style>
