<template>
    <FormsUserProfile v-model="isProfileOpen" />
</template>
<script setup lang="ts">
import { useAuthStore } from '~/store/auth';

const authStore = useAuthStore();
const isProfileOpen = ref(false);
watchEffect(() => {
    if (!authStore.userData || authStore.fetchingUser) {
        return;
    }
    isProfileOpen.value = !(
        authStore.userData.name && !authStore.userData.passwordless
    );
});
</script>
