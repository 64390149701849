import gql from 'graphql-tag';
import {
    AddMutation,
    AddMutationWithCustomHeaders,
    AddQuery,
} from './urql.service';
import type {
    FinishRegistrationPayload,
    UpdateUserProfileMutation,
} from '~/types/user';
import type { AccountStatus, AuthCookie, LoginPayload, RegisterPayload } from '~/types/auth';
import type { MessageResponse } from '~/types/http-loader';

const messageFragment = gql`
    fragment MessageFields on Message {
        id
        message
    }
`;

const Registration = (obj: { registration: RegisterPayload }) => {
    return AddMutation<{ registration: AuthCookie }>(
        gql`
            mutation Registration($registration: RegistrationInput!) {
                registration(input: $registration) {
                    accessToken
                    refreshToken
                    message {
                        ...MessageFields
                    }
                }
            }
            ${messageFragment}
        `,
        obj,
    );
};
const GetInstitutionNameByIp = () => {
    return AddQuery(gql`
        query GetStatus {
            institutionName
        }
    `);
};
const SignInAsInstitution = () => {
    return AddMutation(gql`
        mutation AuthenticateByIp {
            authenticateByIp {
                accessToken
                refreshToken
            }
        }
    `);
};
const AuthenticateUser = (obj: { loginInput: LoginPayload }) => {
    return AddMutation<{ authenticate: AuthCookie }>(
        gql`
            mutation AuthenticateUser($loginInput: LoginInput!) {
                authenticate(input: $loginInput) {
                    accessToken
                    refreshToken
                    message {
                        ...MessageFields
                    }
                }
            }
            ${messageFragment}
        `,
        obj,
    );
};
const GetAccountStatus = (email: string) => {
    return AddQuery<{ accountStatus: AccountStatus }>(
        gql`
            query GetAccountStatus($email: String!) {
                accountStatus(email: $email)
            }
        `,
        { email },
    );
};
const SendLoginToken = (email: string) => {
    return AddMutation<{ sendLoginToken: MessageResponse }>(
        gql`
            mutation SendLoginToken($email: String!) {
                sendLoginToken(email: $email) {
                    ...MessageFields
                }
            }
            ${messageFragment}
        `,
        { email },
    );
};
const AuthenticateWithToken = (token: string) => {
    return AddMutation<{ authenticateWithToken: AuthCookie }>(
        gql`
            mutation AuthenticateUser($token: String!) {
                authenticateWithToken(token: $token) {
                    accessToken
                    refreshToken
                    message {
                        ...MessageFields
                    }
                }
            }
            ${messageFragment}
        `,
        { token },
    );
};
const refreshToken = (customHeaders) => {
    return AddMutationWithCustomHeaders(
        gql`
            mutation RefreshAccess {
                refreshAccess {
                    accessToken
                    refreshToken
                    message {
                        id
                        message
                    }
                }
            }
        `,
        {},
        {
            ...customHeaders,
        },
    );
};

const ForgotPassword = (obj) => {
    return AddMutation(
        gql`
            mutation ResendMailPassword(
                $resendMailPassword: ResetPasswordEmail!
            ) {
                resetPasswordEmail(input: $resendMailPassword) {
                    ...MessageFields
                }
            }
            ${messageFragment}
        `,
        { ...obj },
    );
};
const ResetPassword = (obj) => {
    return AddMutation(
        gql`
            mutation ResetPassword($resendPassword: ResetToken!) {
                resetPassword(input: $resendPassword) {
                    ...MessageFields
                }
            }
            ${messageFragment}
        `,
        { ...obj },
    );
};

const UpdatePassword = (obj) => {
    return AddMutation(
        gql`
            mutation UpdatePassword(
                $userId: String!
                $updatePassword: ConfirmPasswordUpdate!
            ) {
                updatePassword(userId: $userId, data: $updatePassword) {
                    accessToken
                    refreshToken
                    message {
                        id
                        message
                    }
                }
            }
        `,
        { ...obj },
    );
};

const UpdateProfile = <T>(obj: UpdateUserProfileMutation<T>) => {
    return AddMutation<{ updateProfile: { id: string } }>(
        gql`
            mutation UpdateProfile($profileUpdate: SubscriberUpdate!) {
                updateProfile(input: $profileUpdate) {
                    id
                }
            }
        `,
        obj,
    );
};

const FinishRegistration = (obj: {
    finishRegistration: FinishRegistrationPayload;
}) => {
    return AddMutation<{ finishRegistration: { id: string } }>(
        gql`
            mutation FinishRegistration(
                $finishRegistration: FinishRegistrationInput!
            ) {
                finishRegistration(input: $finishRegistration) {
                    id
                }
            }
        `,
        obj,
    );
};

const getUser = () =>
    AddQuery(
        gql`
            query {
                me {
                    id
                    email
                    dob
                    isActive
                    isSubscription
                    isArtist
                    name
                    phoneNumber
                    createdAt
                    signedDevice
                    signedDeviceCounts
                    onboardQuestionnaireCompleted
                    usedMobileApp
                    subscription {
                        product
                        price
                        status
                    }
                    payment {
                        promotionCode
                    }
                    institutionName
                    isSharedAccount
                    partnerSource
                    passwordless
                    segment
                    signupV2
                }
            }
        `,
        { loaderId: 'USER_DATA' },
    );

export {
    Registration,
    GetInstitutionNameByIp,
    SignInAsInstitution,
    AuthenticateUser,
    GetAccountStatus,
    SendLoginToken,
    AuthenticateWithToken,
    getUser,
    refreshToken,
    ForgotPassword,
    ResetPassword,
    UpdateProfile,
    FinishRegistration,
    UpdatePassword,
};
