import type { CombinedError } from '@urql/vue';
import { useQuasar } from 'quasar';

export default function () {
    const $q = useQuasar();
    const logger = useLogs();
    function showAndLogError(error: unknown, extra?: Record<string, unknown>) {
        if ((error as Error).message === 'Auth token expired') {
            return;
        }
        logger.error(error, extra);

        // Hide all the network errors
        if (
            (error as CombinedError).graphQLErrors ||
            (error as CombinedError).networkError
        ) {
            return;
        }
        showErrorMessage(
            error instanceof Error && error.message
                ? error.message
                : 'Error happened, please refresh the page and try again',
        );
    }
    function showErrorMessage(message: string, caption?: string) {
        $q.notify({
            type: 'negative',
            message,
            caption,
            position: 'top',
        });
    }
    function showSuccessMessage(message: string, caption?: string) {
        $q.notify({
            type: 'positive',
            message,
            caption,
            position: 'top',
        });
    }
    return { showAndLogError, showErrorMessage, showSuccessMessage };
}
