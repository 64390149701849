<template>
    <QDialog v-model="isOpen" persistent>
        <QCard class="card">
            <div class="container-general text-center q-pa-md user-profile">
                <h4
                    v-if="!hasPassword && !subscriberName"
                    class="q-mt-none bold-font"
                >
                    Complete sign up
                </h4>
                <h6 v-else class="q-mt-none bold-font">
                      <br>What's your name?
                </h6>
                <form class="form" @submit.prevent="handleSubmit">
                    <AuthInput
                        v-if="!subscriberName"
                        v-model="name"
                        label="Name *"
                        class="q-pb-none q-mb-lg"
                        name="name"
                        outlined
                        required
                    />
                    <AuthInput
                        v-if="!hasPassword"
                        v-model="password"
                        label="Choose a password *"
                        class="q-pb-none q-mb-lg"
                        name="password"
                        type="password"
                        outlined
                        required
                        :rules="passwordRules"
                    />
                    <!-- <q-select
                        v-model="dob"
                        :options="birthYearOptions"
                        outlined
                        class="q-pb-none q-mb-lg"
                        label="Year of birth"
                        menu-anchor="bottom left"
                        menu-self="top left"
                        popup-content-class="select-years-popup"
                    />
                    <div
                        v-if="ageError"
                        class="text-negative text-left relative-position"
                        style="top: -1.5rem"
                    >
                        {{ ageError }}
                    </div>
                    <vue-tel-input
                        v-model="phoneNumberInput"
                        @on-input="handlePhoneNumber"
                    />
                    <div
                        v-if="phoneNumberObj?.number && !phoneNumberObj.valid"
                        class="text-negative text-left relative-position"
                        style="top: -2rem"
                    >
                        Invalid Phone number
                    </div> -->
                    <AuthButton class="button" :loading="loading"
                        >Dive in!</AuthButton
                    >
                </form>
            </div>
        </QCard>
    </QDialog>
</template>
<script setup lang="ts">
import { useAuthStore } from '~/store/auth';
import type {
    UpdateUserProfileMutation,
    UpdateUserProfilePayload,
} from '~/types/user';

// interface PhoneObject {
//     number: string;
//     valid: boolean;
// }

const isOpen = defineModel<boolean>();

const name = ref('');
const password = ref('');

const passwordRules = ref([
    (v: string) => !!v || 'Password is required',
    (v: string) =>
        v.length >= 8 || 'Password must contain at least 8 characters',
]);

// const phoneNumberInput = ref('');
// const phoneNumberObj = ref<PhoneObject | null>(null);

// const birthYearOptions = computed<string[]>(() => {
//     const options: string[] = [];
//     const latestYear = dayjs().year() - 16;
//     for (let year = 1900; year <= latestYear; year += 5) {
//         const upperBound = Math.min(year + 4, latestYear);
//         options.push(`${year}-${upperBound}`);
//     }
//     return options.reverse();
// });
// const dob = ref<string | null>(null);
// const ageError = ref<string | null>(null);

const loading = ref(false);

const authStore = useAuthStore();

const hasPassword = computed(() => !authStore.userData?.passwordless);
const subscriberName = computed(() => authStore.userData?.name || null);

async function updateUserProfile({
    name, // dob,
} // phoneNumber,
: UpdateUserProfilePayload) {
    const userData: UpdateUserProfileMutation = {
        loaderId: 'UPDATE_PROFILE',
        profileUpdate: {
            name,
            // dob,
            // phoneNumber,
        },
    };
    const success = await authStore.updateProfile(userData);
    if (success) {
        authStore.fetchUser();
    }
}

const handleSubmit = async () => {
    if (
        (!subscriberName.value && !name.value) ||
        // (dob.value && ageError.value) ||
        // (phoneNumberObj.value?.number && !phoneNumberObj.value.valid) ||
        (!hasPassword.value && password.value.length < 8)
    ) {
        return;
    }
    const payload: UpdateUserProfilePayload = {
        name: subscriberName.value ?? name.value.trim(),
        // phoneNumber: phoneNumberObj.value?.number ?? '',
        // dob: dob.value ?? '',
    };
    if (hasPassword.value) {
        updateUserProfile(payload);
    } else {
        loading.value = true;
        const success = await authStore.finishRegistration({
            ...payload,
            password: password.value.trim(),
        });
        loading.value = false;
        if (success) {
            authStore.fetchUser();
        }
    }
};

// const handlePhoneNumber = (_: string, phoneObject: PhoneObject) => {
//     phoneNumberObj.value = phoneObject;
// };
</script>
<style lang="scss">
.select-years-popup {
    height: 200px;
}
</style>
<style lang="scss" scoped>
@import '~/public/assets/variables';
@import '~/public/assets/customAnimations';

.card {
    padding: 25px;
    border: 3px solid $lucid-core-blue;
    margin-top: 20px;
    border-radius: 55px;
    color: white;
    animation: animatedLucidGlow 1.7s ease-in-out infinite alternate; 
}
:deep(.q-field--outlined .q-field__control) {
    color: var(--q-blue);
    border-radius: 24px;
    height: 60px;
    &::before {
        border-color: var(--q-blue);
    }
}

:deep(.q-field--outlined) {
    .q-field__native,
    .q-field__prefix,
    .q-field__suffix {
        color: white;
    }
    
    input {
        color: white !important;
    }
}

:deep(.q-dialog__backdrop) {
    background: black !important;
    opacity: 0.9 !important;
}

.form {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    color: white;
}
.button {
    padding: {
        left: 30px;
        right: 30px;
    }
}
:deep(.vue-tel-input) {
    height: 60px;
    border-radius: 24px;
    border-color: var(--q-blue);
    margin-bottom: 2rem;
    color: white;
    &:focus-within {
        box-shadow: none;
    }
    .vti__input {
        background-color: transparent;
    }
    .vti__dropdown-list {
        width: 250px;
        border-radius: 24px;
        &.below {
            top: 58px;
        }

        @media (min-width: 450px) {
            width: 382px;
        }
        @media (min-width: $breakpoint-xsmall) {
            width: 480px;
        }
        @media (min-width: $breakpoint-medium) {
            width: 420px;
        }
    }
    .vti__dropdown {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
    }
    .vti__dropdown-item {
        padding: 10px 15px;
    }
    .vti__search_box {
        width: 100%;
        border: none;
        border-bottom: 1px solid #ccc;
    }
}

@media (min-width: $breakpoint-medium) {
    .card {
        padding: 50px;
    }
}
</style>
