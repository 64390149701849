import type { Partner, PriceTile } from '~/types/product';

export const yearlyPriceWithDiscount: PriceTile = {
    amount: '$3.40',
    promo: '33% off!',
};

const yearlyPrice: PriceTile = {
    amount: '$3.75',
    promo: '25% off!',
};
const monthlyPrice: PriceTile = {
    amount: '$4.99',
};
const yearlySelectPrice: PriceTile = {
    amount: '$2.62',
    promo: 'Extra 25% off!',
};
const monthlySelectPrice: PriceTile = {
    amount: '$3.49',
};

export function getPlanFeatures(upgrade = false) {
    const plans = [
        'Unlimited access to full collection',
        'Enjoy new features & content',
    ];
    if (upgrade) {
        return [...plans, 'Cancel at any time'];
    }
    return [...plans, 'Cancel at any time during trial'];
}

export function getPriceDetails(
    isYearly: boolean,
    partner: Partner | null,
): PriceTile {
    if (partner === 'SELECT') {
        return isYearly ? yearlySelectPrice : monthlySelectPrice;
    }
    return isYearly ? yearlyPrice : monthlyPrice;
}
