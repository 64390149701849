import gql from 'graphql-tag';
import { AddQuery, AddMutation } from './urql.service';
import type { SubscriptionDetails, UpgradePlanVariables } from '~/types/user';

const FetchUpcomingInvoice = (obj) => {
    return AddQuery(
        gql`
            query UpcomingInvoice {
                upcomingInvoice {
                    isoDate
                    timestamp
                }
            }
        `,
        { ...obj },
    );
};

const CancelSubscription = () => {
    return AddMutation(gql`
        mutation CancelSubscription {
            cancelSubscription
        }
    `);
};

const FetchSubscriptionDetails = () => {
    return AddQuery<{ subscriptionDetails: SubscriptionDetails }>(gql`
        query FetchSubscriptionDetails {
            subscriptionDetails {
                discountApplied
                currentPaymentMethod {
                    cardBrand
                    last4
                }
            }
        }
    `);
};

const GetUpdatePaymentMethodUrl = () => {
    return AddMutation(gql`
        mutation GetUpdatePaymentMethodUrl {
            getUpdatePaymentMethodUrl
        }
    `);
};

const PreviewProration = (variables: UpgradePlanVariables) => {
    return AddQuery<{ previewProration: number }>(
        gql`
            query PreviewProration($priceId: String!, $date: Date!) {
                previewProration(priceId: $priceId, date: $date)
            }
        `,
        variables,
    );
};

const UpgradeToAnnualPlan = (variables: UpgradePlanVariables) => {
    return AddMutation(
        gql`
            mutation UpgradeToAnnualPlan($priceId: String!, $date: Date!) {
                upgradeToAnnualPlan(priceId: $priceId, date: $date) {
                    id
                    email
                    dob
                    isActive
                    isSubscription
                    name
                    phoneNumber
                    createdAt
                    signedDevice
                    signedDeviceCounts
                    subscription {
                        product
                        price
                        status
                    }
                    payment {
                        promotionCode
                    }
                }
            }
        `,
        variables,
    );
};

const SaveNewPaymentMethod = (sessionId: string) => {
    return AddMutation(
        gql`
            mutation SaveNewPaymentMethod($sessionId: String!) {
                saveNewPaymentMethod(sessionId: $sessionId) {
                    cardBrand
                    last4
                }
            }
        `,
        { sessionId },
    );
};

export {
    FetchUpcomingInvoice,
    CancelSubscription,
    FetchSubscriptionDetails,
    GetUpdatePaymentMethodUrl,
    PreviewProration,
    UpgradeToAnnualPlan,
    SaveNewPaymentMethod,
};
