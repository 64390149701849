<template>
    <div class="text-center">
        <!-- customise 404 message from template section -->
        <h3>Page not available</h3>
        <p v-if="error.statusCode === 404">Oops this page has moved 🥺</p>

        <!-- Redirect to home page -->
        <q-btn @click="handleError">Go home...</q-btn>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';

defineProps<{
    error: NuxtError;
}>();

const handleError = () => clearError({ redirect: '/' });
</script>
