import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoBaseClub, LazySvgoDown, LazySvgoGhost, LazySvgoLogoSearchBg, LazySvgoTick } from '#components'
const lazyGlobalComponents = [
  ["SvgoBaseClub", LazySvgoBaseClub],
["SvgoDown", LazySvgoDown],
["SvgoGhost", LazySvgoGhost],
["SvgoLogoSearchBg", LazySvgoLogoSearchBg],
["SvgoTick", LazySvgoTick],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
