<template>
    <QInput
        v-bind="$props"
        :type="type"
        class="auth-input"
        :class="{ dark: $props.dark }"
    >
        <template v-if="$props.type === 'password'" #append>
            <q-icon
                :name="passwordHidden ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="passwordHidden = !passwordHidden"
            />
        </template>
    </QInput>
</template>
<script setup lang="ts">
import type { QInputProps } from 'quasar';

interface Props extends QInputProps {}

const props = defineProps<Props>();

const passwordHidden = ref(true);
const type = computed(() => {
    if (props.type !== 'password') {
        return props.type;
    }
    return passwordHidden.value ? 'password' : 'text';
});
</script>
<style lang="scss" scoped>
@import '~/public/assets/variables';
.auth-input {
    width: 500px;
    max-width: 100%;
}

.q-field--outlined {
    :deep(.q-field__control) {
        border-radius: 24px;
        padding: 0 20px;
        height: 60px;
        color: var(--q-secondary);
        &::before {
            border-color: var(--q-secondary);
            border-width: 2px;
        }
        input,
        input::placeholder {
            font-size: 16px;
        }
    }
    &.dark {
        :deep(.q-field__control) {
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-background-clip: text;
                -webkit-text-fill-color: white;
                box-shadow: inset 0 0 50px 50px #000;
            }
        }
        &.q-field--error :deep(.q-field__bottom) {
            color: white;
        }
    }
}
@media (min-width: $breakpoint-medium) {
    .q-field--outlined {
        :deep(.q-field__control) {
            border-radius: 35px;
            
            input,
            input::placeholder {
                font-size: 18px;
            }
        }
    }
}
</style>
