import { default as _91_46_46_46artist_id_93pMnuKOj3xMMeta } from "/codebuild/output/src422867749/src/frontend/pages/artist/[...artist_id].vue?macro=true";
import { default as indexCtM7vroWzNMeta } from "/codebuild/output/src422867749/src/frontend/pages/artist/index.vue?macro=true";
import { default as _91video_slug_93yB2eZXgEHpMeta } from "/codebuild/output/src422867749/src/frontend/pages/auto/[video_slug].vue?macro=true";
import { default as index3oeguibO6HMeta } from "/codebuild/output/src422867749/src/frontend/pages/auto/index.vue?macro=true";
import { default as _91artist_slug_93yGjZrlqkEyMeta } from "/codebuild/output/src422867749/src/frontend/pages/bio/[artist_slug].vue?macro=true";
import { default as _91video_slug_939DFCPxy6yDMeta } from "/codebuild/output/src422867749/src/frontend/pages/browse/[video_slug].vue?macro=true";
import { default as indexzCjrHijA9QMeta } from "/codebuild/output/src422867749/src/frontend/pages/browse/index.vue?macro=true";
import { default as indexhQHIld0c2LMeta } from "/codebuild/output/src422867749/src/frontend/pages/cancel/index.vue?macro=true";
import { default as _91categoryId_93kuxQ3DrbckMeta } from "/codebuild/output/src422867749/src/frontend/pages/category/[categoryId].vue?macro=true";
import { default as codeUzJ6LUEPMvMeta } from "/codebuild/output/src422867749/src/frontend/pages/code.vue?macro=true";
import { default as _91id_93GwFVSFvFxdMeta } from "/codebuild/output/src422867749/src/frontend/pages/collections/[id].vue?macro=true";
import { default as connectedmrdTeyic7EMeta } from "/codebuild/output/src422867749/src/frontend/pages/collections/connected.vue?macro=true";
import { default as indexUcYoC9EXOxMeta } from "/codebuild/output/src422867749/src/frontend/pages/collections/index.vue?macro=true";
import { default as myzVJ8iYMUAbMeta } from "/codebuild/output/src422867749/src/frontend/pages/collections/my.vue?macro=true";
import { default as continuehVwd2j6B4DMeta } from "/codebuild/output/src422867749/src/frontend/pages/continue.vue?macro=true";
import { default as new_45payment_45method9faqt7G4OCMeta } from "/codebuild/output/src422867749/src/frontend/pages/failure/new-payment-method.vue?macro=true";
import { default as indexRoKjKk3cvlMeta } from "/codebuild/output/src422867749/src/frontend/pages/ghost/index.vue?macro=true";
import { default as indexFqDvmRnH4EMeta } from "/codebuild/output/src422867749/src/frontend/pages/index.vue?macro=true";
import { default as loginCAvuRiUlzcMeta } from "/codebuild/output/src422867749/src/frontend/pages/login.vue?macro=true";
import { default as mobile_45appNc4H64eG3JMeta } from "/codebuild/output/src422867749/src/frontend/pages/mobile-app.vue?macro=true";
import { default as indexO8RUIiyRJkMeta } from "/codebuild/output/src422867749/src/frontend/pages/my-account/index.vue?macro=true";
import { default as qzFlowfm26sCMx1OMeta } from "/codebuild/output/src422867749/src/frontend/pages/onboard/qzFlow.vue?macro=true";
import { default as onboardingQIFOjtzLJHMeta } from "/codebuild/output/src422867749/src/frontend/pages/onboarding.vue?macro=true";
import { default as playlistKD8CkKaJVOMeta } from "/codebuild/output/src422867749/src/frontend/pages/playlist.vue?macro=true";
import { default as questionsYbib0dD3OXMeta } from "/codebuild/output/src422867749/src/frontend/pages/questions.vue?macro=true";
import { default as _91token_93xYbpEi6PxWMeta } from "/codebuild/output/src422867749/src/frontend/pages/reset-password/[token].vue?macro=true";
import { default as index9KDTSBQqWAMeta } from "/codebuild/output/src422867749/src/frontend/pages/reset-password/index.vue?macro=true";
import { default as indexBu29bGrC5KMeta } from "/codebuild/output/src422867749/src/frontend/pages/search/index.vue?macro=true";
import { default as selectDGCkH5vvJfMeta } from "/codebuild/output/src422867749/src/frontend/pages/select.vue?macro=true";
import { default as a_45_91artistSlug_938FBckgNpPWMeta } from "/codebuild/output/src422867749/src/frontend/pages/share/a-[artistSlug].vue?macro=true";
import { default as c_45_91collectionId_93G612Mm3xehMeta } from "/codebuild/output/src422867749/src/frontend/pages/share/c-[collectionId].vue?macro=true";
import { default as v_45_91videoSlug_93ygfiACDb3mMeta } from "/codebuild/output/src422867749/src/frontend/pages/share/v-[videoSlug].vue?macro=true";
import { default as indexrJM9KJlfUEMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/base-club/index.vue?macro=true";
import { default as planseAPYy63DNCMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/base-club/plans.vue?macro=true";
import { default as indexNb20k9zMhUMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/index.vue?macro=true";
import { default as plansvy5jo7nm8fMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/plans.vue?macro=true";
import { default as indexQlPujD0gPqMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/promo/index.vue?macro=true";
import { default as indexxblfMIzhvyMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/select/index.vue?macro=true";
import { default as plansPYTOJlybHpMeta } from "/codebuild/output/src422867749/src/frontend/pages/signup/select/plans.vue?macro=true";
import { default as start6zRX5vvpzRMeta } from "/codebuild/output/src422867749/src/frontend/pages/start.vue?macro=true";
import { default as indexmolYbp5x3XMeta } from "/codebuild/output/src422867749/src/frontend/pages/success/index.vue?macro=true";
import { default as new_45payment_45methodIq23vSyJwIMeta } from "/codebuild/output/src422867749/src/frontend/pages/success/new-payment-method.vue?macro=true";
import { default as terms_of_useTPtNndFHZlMeta } from "/codebuild/output/src422867749/src/frontend/pages/terms_of_use.vue?macro=true";
export default [
  {
    name: _91_46_46_46artist_id_93pMnuKOj3xMMeta?.name ?? "artist-artist_id",
    path: _91_46_46_46artist_id_93pMnuKOj3xMMeta?.path ?? "/artist/:artist_id(.*)*",
    meta: _91_46_46_46artist_id_93pMnuKOj3xMMeta || {},
    alias: _91_46_46_46artist_id_93pMnuKOj3xMMeta?.alias || [],
    redirect: _91_46_46_46artist_id_93pMnuKOj3xMMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/artist/[...artist_id].vue").then(m => m.default || m)
  },
  {
    name: indexCtM7vroWzNMeta?.name ?? "artist",
    path: indexCtM7vroWzNMeta?.path ?? "/artist",
    meta: indexCtM7vroWzNMeta || {},
    alias: indexCtM7vroWzNMeta?.alias || [],
    redirect: indexCtM7vroWzNMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/artist/index.vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_93yB2eZXgEHpMeta?.name ?? "auto-video_slug",
    path: _91video_slug_93yB2eZXgEHpMeta?.path ?? "/auto/:video_slug()",
    meta: _91video_slug_93yB2eZXgEHpMeta || {},
    alias: _91video_slug_93yB2eZXgEHpMeta?.alias || [],
    redirect: _91video_slug_93yB2eZXgEHpMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/auto/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: index3oeguibO6HMeta?.name ?? "auto",
    path: index3oeguibO6HMeta?.path ?? "/auto",
    meta: index3oeguibO6HMeta || {},
    alias: index3oeguibO6HMeta?.alias || [],
    redirect: index3oeguibO6HMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/auto/index.vue").then(m => m.default || m)
  },
  {
    name: _91artist_slug_93yGjZrlqkEyMeta?.name ?? "bio-artist_slug",
    path: _91artist_slug_93yGjZrlqkEyMeta?.path ?? "/bio/:artist_slug()",
    meta: _91artist_slug_93yGjZrlqkEyMeta || {},
    alias: _91artist_slug_93yGjZrlqkEyMeta?.alias || [],
    redirect: _91artist_slug_93yGjZrlqkEyMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/bio/[artist_slug].vue").then(m => m.default || m)
  },
  {
    name: _91video_slug_939DFCPxy6yDMeta?.name ?? "browse-video_slug",
    path: _91video_slug_939DFCPxy6yDMeta?.path ?? "/browse/:video_slug()",
    meta: _91video_slug_939DFCPxy6yDMeta || {},
    alias: _91video_slug_939DFCPxy6yDMeta?.alias || [],
    redirect: _91video_slug_939DFCPxy6yDMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/browse/[video_slug].vue").then(m => m.default || m)
  },
  {
    name: indexzCjrHijA9QMeta?.name ?? "browse",
    path: indexzCjrHijA9QMeta?.path ?? "/browse",
    meta: indexzCjrHijA9QMeta || {},
    alias: indexzCjrHijA9QMeta?.alias || [],
    redirect: indexzCjrHijA9QMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexhQHIld0c2LMeta?.name ?? "cancel",
    path: indexhQHIld0c2LMeta?.path ?? "/cancel",
    meta: indexhQHIld0c2LMeta || {},
    alias: indexhQHIld0c2LMeta?.alias || [],
    redirect: indexhQHIld0c2LMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: _91categoryId_93kuxQ3DrbckMeta?.name ?? "category-categoryId",
    path: _91categoryId_93kuxQ3DrbckMeta?.path ?? "/category/:categoryId()",
    meta: _91categoryId_93kuxQ3DrbckMeta || {},
    alias: _91categoryId_93kuxQ3DrbckMeta?.alias || [],
    redirect: _91categoryId_93kuxQ3DrbckMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/category/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: codeUzJ6LUEPMvMeta?.name ?? "code",
    path: codeUzJ6LUEPMvMeta?.path ?? "/code",
    meta: codeUzJ6LUEPMvMeta || {},
    alias: codeUzJ6LUEPMvMeta?.alias || [],
    redirect: codeUzJ6LUEPMvMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/code.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GwFVSFvFxdMeta?.name ?? "collections-id",
    path: _91id_93GwFVSFvFxdMeta?.path ?? "/collections/:id()",
    meta: _91id_93GwFVSFvFxdMeta || {},
    alias: _91id_93GwFVSFvFxdMeta?.alias || [],
    redirect: _91id_93GwFVSFvFxdMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: connectedmrdTeyic7EMeta?.name ?? "collections-connected",
    path: connectedmrdTeyic7EMeta?.path ?? "/collections/connected",
    meta: connectedmrdTeyic7EMeta || {},
    alias: connectedmrdTeyic7EMeta?.alias || [],
    redirect: connectedmrdTeyic7EMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/collections/connected.vue").then(m => m.default || m)
  },
  {
    name: indexUcYoC9EXOxMeta?.name ?? "collections",
    path: indexUcYoC9EXOxMeta?.path ?? "/collections",
    meta: indexUcYoC9EXOxMeta || {},
    alias: indexUcYoC9EXOxMeta?.alias || [],
    redirect: indexUcYoC9EXOxMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: myzVJ8iYMUAbMeta?.name ?? "collections-my",
    path: myzVJ8iYMUAbMeta?.path ?? "/collections/my",
    meta: myzVJ8iYMUAbMeta || {},
    alias: myzVJ8iYMUAbMeta?.alias || [],
    redirect: myzVJ8iYMUAbMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/collections/my.vue").then(m => m.default || m)
  },
  {
    name: continuehVwd2j6B4DMeta?.name ?? "continue",
    path: continuehVwd2j6B4DMeta?.path ?? "/continue",
    meta: continuehVwd2j6B4DMeta || {},
    alias: continuehVwd2j6B4DMeta?.alias || [],
    redirect: continuehVwd2j6B4DMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/continue.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45method9faqt7G4OCMeta?.name ?? "failure-new-payment-method",
    path: new_45payment_45method9faqt7G4OCMeta?.path ?? "/failure/new-payment-method",
    meta: new_45payment_45method9faqt7G4OCMeta || {},
    alias: new_45payment_45method9faqt7G4OCMeta?.alias || [],
    redirect: new_45payment_45method9faqt7G4OCMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/failure/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: indexRoKjKk3cvlMeta?.name ?? "ghost",
    path: indexRoKjKk3cvlMeta?.path ?? "/ghost",
    meta: indexRoKjKk3cvlMeta || {},
    alias: indexRoKjKk3cvlMeta?.alias || [],
    redirect: indexRoKjKk3cvlMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/ghost/index.vue").then(m => m.default || m)
  },
  {
    name: indexFqDvmRnH4EMeta?.name ?? "index",
    path: indexFqDvmRnH4EMeta?.path ?? "/",
    meta: indexFqDvmRnH4EMeta || {},
    alias: indexFqDvmRnH4EMeta?.alias || [],
    redirect: indexFqDvmRnH4EMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginCAvuRiUlzcMeta?.name ?? "login",
    path: loginCAvuRiUlzcMeta?.path ?? "/login",
    meta: loginCAvuRiUlzcMeta || {},
    alias: loginCAvuRiUlzcMeta?.alias || [],
    redirect: loginCAvuRiUlzcMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mobile_45appNc4H64eG3JMeta?.name ?? "mobile-app",
    path: mobile_45appNc4H64eG3JMeta?.path ?? "/mobile-app",
    meta: mobile_45appNc4H64eG3JMeta || {},
    alias: mobile_45appNc4H64eG3JMeta?.alias || [],
    redirect: mobile_45appNc4H64eG3JMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: indexO8RUIiyRJkMeta?.name ?? "my-account",
    path: indexO8RUIiyRJkMeta?.path ?? "/my-account",
    meta: indexO8RUIiyRJkMeta || {},
    alias: indexO8RUIiyRJkMeta?.alias || [],
    redirect: indexO8RUIiyRJkMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: qzFlowfm26sCMx1OMeta?.name ?? "onboard-qzFlow",
    path: qzFlowfm26sCMx1OMeta?.path ?? "/onboard/qzFlow",
    meta: qzFlowfm26sCMx1OMeta || {},
    alias: qzFlowfm26sCMx1OMeta?.alias || [],
    redirect: qzFlowfm26sCMx1OMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/onboard/qzFlow.vue").then(m => m.default || m)
  },
  {
    name: onboardingQIFOjtzLJHMeta?.name ?? "onboarding",
    path: onboardingQIFOjtzLJHMeta?.path ?? "/onboarding",
    meta: onboardingQIFOjtzLJHMeta || {},
    alias: onboardingQIFOjtzLJHMeta?.alias || [],
    redirect: onboardingQIFOjtzLJHMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: playlistKD8CkKaJVOMeta?.name ?? "playlist",
    path: playlistKD8CkKaJVOMeta?.path ?? "/playlist",
    meta: playlistKD8CkKaJVOMeta || {},
    alias: playlistKD8CkKaJVOMeta?.alias || [],
    redirect: playlistKD8CkKaJVOMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/playlist.vue").then(m => m.default || m)
  },
  {
    name: questionsYbib0dD3OXMeta?.name ?? "questions",
    path: questionsYbib0dD3OXMeta?.path ?? "/questions",
    meta: questionsYbib0dD3OXMeta || {},
    alias: questionsYbib0dD3OXMeta?.alias || [],
    redirect: questionsYbib0dD3OXMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: _91token_93xYbpEi6PxWMeta?.name ?? "reset-password-token",
    path: _91token_93xYbpEi6PxWMeta?.path ?? "/reset-password/:token()",
    meta: _91token_93xYbpEi6PxWMeta || {},
    alias: _91token_93xYbpEi6PxWMeta?.alias || [],
    redirect: _91token_93xYbpEi6PxWMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: index9KDTSBQqWAMeta?.name ?? "reset-password",
    path: index9KDTSBQqWAMeta?.path ?? "/reset-password",
    meta: index9KDTSBQqWAMeta || {},
    alias: index9KDTSBQqWAMeta?.alias || [],
    redirect: index9KDTSBQqWAMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexBu29bGrC5KMeta?.name ?? "search",
    path: indexBu29bGrC5KMeta?.path ?? "/search",
    meta: indexBu29bGrC5KMeta || {},
    alias: indexBu29bGrC5KMeta?.alias || [],
    redirect: indexBu29bGrC5KMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: selectDGCkH5vvJfMeta?.name ?? "select",
    path: selectDGCkH5vvJfMeta?.path ?? "/select",
    meta: selectDGCkH5vvJfMeta || {},
    alias: selectDGCkH5vvJfMeta?.alias || [],
    redirect: selectDGCkH5vvJfMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/select.vue").then(m => m.default || m)
  },
  {
    name: a_45_91artistSlug_938FBckgNpPWMeta?.name ?? "share-a-artistSlug",
    path: a_45_91artistSlug_938FBckgNpPWMeta?.path ?? "/share/a-:artistSlug()",
    meta: a_45_91artistSlug_938FBckgNpPWMeta || {},
    alias: a_45_91artistSlug_938FBckgNpPWMeta?.alias || [],
    redirect: a_45_91artistSlug_938FBckgNpPWMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/share/a-[artistSlug].vue").then(m => m.default || m)
  },
  {
    name: c_45_91collectionId_93G612Mm3xehMeta?.name ?? "share-c-collectionId",
    path: c_45_91collectionId_93G612Mm3xehMeta?.path ?? "/share/c-:collectionId()",
    meta: c_45_91collectionId_93G612Mm3xehMeta || {},
    alias: c_45_91collectionId_93G612Mm3xehMeta?.alias || [],
    redirect: c_45_91collectionId_93G612Mm3xehMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/share/c-[collectionId].vue").then(m => m.default || m)
  },
  {
    name: v_45_91videoSlug_93ygfiACDb3mMeta?.name ?? "share-v-videoSlug",
    path: v_45_91videoSlug_93ygfiACDb3mMeta?.path ?? "/share/v-:videoSlug()",
    meta: v_45_91videoSlug_93ygfiACDb3mMeta || {},
    alias: v_45_91videoSlug_93ygfiACDb3mMeta?.alias || [],
    redirect: v_45_91videoSlug_93ygfiACDb3mMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/share/v-[videoSlug].vue").then(m => m.default || m)
  },
  {
    name: indexrJM9KJlfUEMeta?.name ?? "signup-base-club",
    path: indexrJM9KJlfUEMeta?.path ?? "/signup/base-club",
    meta: indexrJM9KJlfUEMeta || {},
    alias: indexrJM9KJlfUEMeta?.alias || [],
    redirect: indexrJM9KJlfUEMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/base-club/index.vue").then(m => m.default || m)
  },
  {
    name: planseAPYy63DNCMeta?.name ?? "signup-base-club-plans",
    path: planseAPYy63DNCMeta?.path ?? "/signup/base-club/plans",
    meta: planseAPYy63DNCMeta || {},
    alias: planseAPYy63DNCMeta?.alias || [],
    redirect: planseAPYy63DNCMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/base-club/plans.vue").then(m => m.default || m)
  },
  {
    name: indexNb20k9zMhUMeta?.name ?? "signup",
    path: indexNb20k9zMhUMeta?.path ?? "/signup",
    meta: indexNb20k9zMhUMeta || {},
    alias: indexNb20k9zMhUMeta?.alias || [],
    redirect: indexNb20k9zMhUMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: plansvy5jo7nm8fMeta?.name ?? "signup-plans",
    path: plansvy5jo7nm8fMeta?.path ?? "/signup/plans",
    meta: plansvy5jo7nm8fMeta || {},
    alias: plansvy5jo7nm8fMeta?.alias || [],
    redirect: plansvy5jo7nm8fMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/plans.vue").then(m => m.default || m)
  },
  {
    name: indexQlPujD0gPqMeta?.name ?? "signup-promo",
    path: indexQlPujD0gPqMeta?.path ?? "/signup/promo",
    meta: indexQlPujD0gPqMeta || {},
    alias: indexQlPujD0gPqMeta?.alias || [],
    redirect: indexQlPujD0gPqMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/promo/index.vue").then(m => m.default || m)
  },
  {
    name: indexxblfMIzhvyMeta?.name ?? "signup-select",
    path: indexxblfMIzhvyMeta?.path ?? "/signup/select",
    meta: indexxblfMIzhvyMeta || {},
    alias: indexxblfMIzhvyMeta?.alias || [],
    redirect: indexxblfMIzhvyMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/select/index.vue").then(m => m.default || m)
  },
  {
    name: plansPYTOJlybHpMeta?.name ?? "signup-select-plans",
    path: plansPYTOJlybHpMeta?.path ?? "/signup/select/plans",
    meta: plansPYTOJlybHpMeta || {},
    alias: plansPYTOJlybHpMeta?.alias || [],
    redirect: plansPYTOJlybHpMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/signup/select/plans.vue").then(m => m.default || m)
  },
  {
    name: start6zRX5vvpzRMeta?.name ?? "start",
    path: start6zRX5vvpzRMeta?.path ?? "/start",
    meta: start6zRX5vvpzRMeta || {},
    alias: start6zRX5vvpzRMeta?.alias || [],
    redirect: start6zRX5vvpzRMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/start.vue").then(m => m.default || m)
  },
  {
    name: indexmolYbp5x3XMeta?.name ?? "success",
    path: indexmolYbp5x3XMeta?.path ?? "/success",
    meta: indexmolYbp5x3XMeta || {},
    alias: indexmolYbp5x3XMeta?.alias || [],
    redirect: indexmolYbp5x3XMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: new_45payment_45methodIq23vSyJwIMeta?.name ?? "success-new-payment-method",
    path: new_45payment_45methodIq23vSyJwIMeta?.path ?? "/success/new-payment-method",
    meta: new_45payment_45methodIq23vSyJwIMeta || {},
    alias: new_45payment_45methodIq23vSyJwIMeta?.alias || [],
    redirect: new_45payment_45methodIq23vSyJwIMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/success/new-payment-method.vue").then(m => m.default || m)
  },
  {
    name: terms_of_useTPtNndFHZlMeta?.name ?? "terms_of_use",
    path: terms_of_useTPtNndFHZlMeta?.path ?? "/terms_of_use",
    meta: terms_of_useTPtNndFHZlMeta || {},
    alias: terms_of_useTPtNndFHZlMeta?.alias || [],
    redirect: terms_of_useTPtNndFHZlMeta?.redirect,
    component: () => import("/codebuild/output/src422867749/src/frontend/pages/terms_of_use.vue").then(m => m.default || m)
  }
]