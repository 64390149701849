import gql from 'graphql-tag';
import { AddMutation, AddQuery } from './urql.service';
import type { CheckoutPayload, FetchProductsResponse } from '~/types/product';

const Checkout = (obj: CheckoutPayload) => {
    return AddMutation<{ checkout: { url: string } }>(
        gql`
            mutation Checkout($checkout: CheckoutData) {
                checkout(input: $checkout) {
                    url
                }
            }
        `,
        obj,
    );
};

const GetAllProducts = (obj) => {
    return AddQuery<FetchProductsResponse>(
        gql`
            query GetAllProducts($filter: FilterPaginations) {
                fetchActiveProducts(filter: $filter) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasNextPage
                        hasPreviousPage
                    }
                    edges {
                        node {
                            id
                            prices(filter: $filter) {
                                previousCount
                                currentCount
                                totalCount
                                edges {
                                    node {
                                        id
                                        stripePriceId
                                        recurring {
                                            interval
                                        }
                                    }
                                }
                                pageInfo {
                                    hasNextPage
                                }
                            }
                            stripeProductId
                            # isActive
                        }
                    }
                }
            }
        `,
        { ...obj },
    );
};

const GetActiveProducts = (obj) => {
    return AddQuery(
        gql`
            query GetQueryActiveProducts($filter: FilterPaginations!) {
                fetchActiveProducts(filter: $filter) {
                    previousCount
                    currentCount
                    totalCount
                    edges {
                        cursor
                        node {
                            id
                            stripeProductId
                            isActive
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }
        `,
        { ...obj },
    );
};

const GetProductById = (obj) => {
    return AddQuery(
        gql`
            query FetchProductById(
                $productId: String!
                $filter: FilterPaginations
            ) {
                getProductById(id: $productId) {
                    id
                    default_price
                    prices(filter: $filter) {
                        previousCount
                        currentCount
                        totalCount
                        edges {
                            node {
                                id
                                stripePriceId
                                name
                                description
                            }
                        }
                        pageInfo {
                            hasNextPage
                        }
                    }
                    name
                    description
                    subscription
                    stripeProductId
                }
            }
        `,
        { ...obj },
    );
};

export { GetAllProducts, Checkout, GetActiveProducts, GetProductById };
