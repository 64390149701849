import type { Dictionary } from 'rollbar';

export default function () {
    const { $rollbar } = useNuxtApp();
    function log(...params: unknown[]) {
        print('log', params);
    }

    function trace(...params: string[]) {
        print('trace', params);
    }

    function info(...params: unknown[]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $rollbar?.info(...(params as any[]));
        print('info', params);
    }

    function error(
        errorParam: unknown,
        extra?: Dictionary,
        ...consoleExtra: unknown[]
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $rollbar?.error(errorParam as any, extra);
        print('error', [errorParam, extra, ...consoleExtra]);
    }

    function print(
        func: 'log' | 'info' | 'error' | 'trace',
        params: unknown[],
    ) {
        if (import.meta.env.MODE === 'development') {
            // eslint-disable-next-line no-console
            console[func](...params);
        }
    }

    return {
        error,
        log,
        info,
        trace,
    };
}
