import gql from 'graphql-tag';
import { AddMutation } from './urql.service';

const SendDeviceInfo = (obj) => {
    return AddMutation(
        gql`
            mutation logUserDevice($deviceInput: DeviceInput!) {
                logUserDevice(input: $deviceInput) {
                    id
                    subscriber
                }
            }
        `,
        { ...obj },
    );
};

export { SendDeviceInfo };
