import Rollbar from 'rollbar';

export default defineNuxtPlugin((nuxtApp) => {
    if (
        !window.location.host.includes('127.0.0.1') &&
        !window.location.host.includes('localhost')
    ) {
        const { rollbarAccessToken, env, version } = nuxtApp.$config.public;
        const rollbar = new Rollbar({
            accessToken: rollbarAccessToken,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: env,
                client: {
                    javascript: {
                        source_map_enabled: true,
                        code_version: version,
                    },
                },
            },
        });
        nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
            rollbar.error(error, { info });
            if (import.meta.env.MODE === 'development') {
                console.error(error);
            }
        };
        nuxtApp.vueApp.config.warnHandler = (error, instance, info) => {
            rollbar.warn(error, { info });
            if (import.meta.env.MODE === 'development') {
                console.warn(error);
            }
        };
        return {
            provide: {
                rollbar: rollbar as Rollbar | undefined,
            },
        };
    }
});
