import { Quasar, Dialog, Loading, Cookies, Notify } from 'quasar';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';

// Import Quasar css
import 'quasar/dist/quasar.css';

export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.vueApp.use(Quasar, {
        config: {
            brand: {
                primary: '#000000',         // $black in _variables.scss
                white: '#ffffff',           // $white in _variables.scss

                // Lucid Brand Colors
                blue: '#1BE8FF',            // $lucid-core-blue in _variables.scss
                secondary: '#1BE8FF',       // $lucid-core-blue in _variables.scss
                'secondary-lighten-1': '#1BE8FF',
                'secondary-lighten': '#e1f7ff',

                // Gray Scale
                tertiary: '#e4e4e4',        // $gray-light in _variables.scss
                'tertiary-1': '#333',       // $gray-dark in _variables.scss
                'tertiary-2': '#f8f8f8',    // $off-white in _variables.scss
                'grey-lighten': '#efefef',
                'text-grey-6': '#278491',          // $lucid-blue-extradark in _variables.scss

                // Dark Mode Colors
                dark: '#000000',            // Dark mode background # 010202
                'dark-page': '#000000',     // Dark mode page

                // System/Feedback Colors
                positive: '#9cf2ff',
                negative: '#82ffcd',
                info: '##26b5c6',
                warning: '#26b5c6', 
                accent: '#26b5c6',
            },
            dark: true,                   // changed from false
            loading: {},

            notify: {
                textColor: 'black',  // default text color for notifications
                position: 'top',
                timeout: 2000
            }
        },
        plugins: {
            Dialog,
            Loading,
            Cookies,
            Notify,
        },
    });
});
